.navbar-menu {
  position: fixed;
  top: 0;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
}

.bm-burger-button {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  position: fixed !important;
  top: 50px;
  right: 40px;
  padding: 10px;
  width: 40px !important;
  height: 40px !important;
}

.bm-burger-button button {
  width: 100% !important;
  height: 100% !important;
}

.bm-burger-button:hover {
  background-color: rgba(128, 128, 128, 0.089);
  border-radius: 50%;
}

.bm-menu-wrap {
  background-color: #000000;
}

.bm-item {
  align-self: baseline;
  font-size: 35px;
  display: inline-block;
  text-decoration: none !important;
  margin-bottom: 10px;
  color: #c4bdbd;
  transition: color 0.3s;
}

.bm-item:hover {
  color: #ffffff !important;
}

.menu {
  position: absolute;
  z-index: 1100;
  top: 0;
  width: 50% !important;
  height: 100% !important;
}

.menu-item {
  font-family: "BebasNeueLight";
  font-weight: bold;
  color: #7b7b7b;
  letter-spacing: 8px;
  margin-left: auto !important;
  text-align: right !important;
}

.bm-burger-bars {
  background: #0b0ba2;
}

.language-selector-menu {
  background-color: transparent !important;
  border: none !important;
  cursor: pointer;
  width: 70px !important;
  height: 40px !important;
  color: #ffffff !important;
  position: absolute !important;
  z-index: 999;
  top: 6px;
  right: 80px;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
  flex-direction: row !important;
  padding: 0 !important;
  margin: 0 !important;
}

.language-selector-menu-text,
.language-selector-menu-item {
  font-family: "BebasNeue" !important;
  letter-spacing: 1px !important;
  font-weight: lighter !important;
}

.language-selector-menu-item {
  font-size: 18px !important;
}

.language-selector-menu-text {
  padding: 0 !important;
  margin: 0 !important;
  width: 30px;
  font-size: 23px !important;
  margin-top: 1px !important;
  color: rgb(191, 188, 188) !important;
}

.language-selector-menu-icon {
  height: 35px !important;
  max-width: 60px !important;
  font-size: 30px !important;
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 1px !important;
}

.bm-cross-button {
  padding: 5px;
}

.bm-cross-button,
.bm-cross {
  z-index: 999;
  margin-right: 35px;
  height: 25px !important;
  width: 25px !important;
  border-radius: 50%;
}

.bm-cross-button:hover {
  background-color: rgba(99, 94, 94, 0.248);
}

.bm-morph-shape {
  fill: #ffffff;
}

.bm-menu {
  width: 100%;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  display: flex;
  flex-direction: column !important;
  text-align: right !important;
  align-content: center;
  justify-content: center !important;
  align-items: flex-end !important;
}

.bm-item-list {
  width: 100%;
  font-weight: 300;
  display: flex;
  flex-direction: column !important;
  text-align: right !important;
  align-content: flex-end;
  justify-content: center !important;
  align-items: flex-end !important;
}

.bm-overlay {
  background: rgba(0, 0, 0, 0.576) !important;
}

.menu-item-sup {
  font-family: "BebasNeue";
  letter-spacing: 1px;
  font-size: 15px;
  margin-left: 5px;
  margin-right: 20px;
}

.menu-background {
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

/* Media queries */

/* Height */

@media (max-height: 600px) {
  .bm-item {
    font-size: 23px;
  }
}

@media (max-height: 420px) {
  .menu-item {
    text-align: left !important;
    padding-bottom: 10px !important;
    margin: 0 !important;
    font-size: 19px !important;
  }

  .menu-item-sup {
    display: none;
  }
}

@media (max-height: 375px) {
  .menu-item {
    font-size: 17px !important;
    letter-spacing: 4px !important;
  }
}

@media (max-height: 245px) {
  .menu-item {
    font-size: 13px !important;
    letter-spacing: 3px !important;
  }
}

/* Width */

@media (max-width: 980px) {
  .bm-item {
    font-size: 25px;
  }

  .menu-item-sup {
    font-size: 12px;
  }
}

@media (max-width: 850px) {
  .menu {
    width: 100% !important;
  }
}

@media (max-width: 740px) {
  .bm-item {
    font-size: 23px;
  }

  .menu-item-sup {
    font-size: 11px;
  }
}

@media (max-width: 800px) {
  .menu {
    width: 70% !important;
  }
}

@media (max-width: 550px) {
  .menu {
    width: 100% !important;
  }
}

@media (max-width: 450px) {
  .bm-item {
    font-size: 25px;
    letter-spacing: 3px;
  }

  .menu-item-sup {
    font-size: 12px;
  }

  .bm-burger-button {
    right: 20px !important;
  }
}

/* Width and height */

@media (max-width: 990px), (max-height: 600px) {
  .navbar-menu {
    height: 70px;
  }

  .bm-burger-button {
    top: 5px !important;
  }
}
