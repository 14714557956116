.home-footer-section {
  position: relative;
  display: grid;
  height: 100%;
  grid-template-rows: 25% 25% 25% 25%;
}

.home-footer-section-row1,
.home-footer-section-row2,
.home-footer-section-row3,
.home-footer-section-row4 {
  display: flex;
  justify-content: center;
}

.home-footer-section-row1 {
  align-items: center;
}

.home-footer-section-row2 {
  align-items: flex-start;
}

.home-footer-section-row3 {
  align-items: flex-start;
}

.home-footer-section-row4 {
  align-items: flex-start;
  justify-content: center;
}

.home-footer-back {
  position: relative;
  z-index: 999;
  cursor: pointer;
}

.home-footer-back-image {
  height: 25px;
  width: auto;
  transform: rotate(180deg);
}

.home-footer-back-image:hover {
  filter: opacity(0.5);
}

.footer-logo {
  padding: 10px;
  width: 130px;
}

.home-footer-row3-title {
  color: rgb(213, 212, 212);
  font-family: "BebasNeue";
  letter-spacing: 6px;
  font-weight: 300;
  font-size: 35px;
  margin: 0;
}

.home-footer-row4-contact {
  display: flex;
  flex-direction: row;
  margin: 0;
  text-align: left !important;
  height: auto;
}

.home-footer-row4-social {
  padding: 10px 0 0 0 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.home-footer-row4-social-icon {
  margin: 0 5px 0 5px;
  font-size: 30px !important;
  color: #ffffff !important;
  -webkit-transition: color 0.8s ease-in-out;
  -moz-transition: color 0.8s ease-in-out;
  -o-transition: color 0.8s ease-in-out;
  -ms-transition: color 0.8s ease-in-out;
  transition: color 0.8s ease-in-out;
}

.home-footer-row4-social-icon:hover {
  font-size: 30px !important;
  color: red !important;
}

.home-footer-row4-cl {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-right: 30px;
  margin-left: 30px;
  height: 150px;
}

.home-footer-row4-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-footer-row4-divider {
  max-height: 3000px;
  width: 1px;
  margin-left: 30px;
  margin-right: 30px;
}

.home-footer-row4-input {
  margin-top: 5px !important;
  margin-bottom: 20px !important;
}

#input-with-icon-grid {
  min-width: 220px;
}

.MuiInput-underline:before {
  border-bottom: 2px solid #ffffff !important;
}

.MuiInput-underline:after {
  border-bottom: 2px solid #ff0000 !important;
}

.MuiFormLabel-root,
.MuiInputBase-root,
.MuiInput-underline {
  color: #dccaca !important;
}

.home-footer-row4-input-icon {
  border: 1px solid #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.home-footer-row4-input-icon:hover {
  color: red;
}

.home-footer-row4-copy {
  color: #726f6f;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 1px;
  margin: 0;
}

.home-footer-row4-icon {
  width: 30px;
}

.home-footer-row4-title {
  color: #c9c1c1;
  font-family: "BebasNeue";
  font-weight: 300;
  font-size: 23px;
  padding-top: 0px;
  margin: 0 !important;
}

.home-footer-row4-text {
  color: #ffffff;
  text-align: center;
  overflow-wrap: break-word;
  font-family: "BebasNeueLight";
  letter-spacing: 1px;
  font-weight: 100;
  font-size: 20px;
  margin: 0 !important;
}

.home-footer-row4-copy,
.home-footer-row4-cookies {
  max-width: 270px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-footer-row4-copy {
  padding: 0 !important;
}

.home-footer-row4-copy-text {
  padding: 0 5px 0 5px !important;
  margin: 10px 0 5px 0 !important;
  text-align: center;
  font-family: "BebasNeueLight";
  letter-spacing: 1px;
  font-size: 18px;
  color: #ffffff;
}

.home-footer-row4-cookies-text {
  padding: 0 5px 0 5px !important;
  margin: 10px 0 10px 0 !important;
  text-align: center;
  font-family: "BebasNeueLight";
  letter-spacing: 1px;
  font-size: 15px;
  color: #ffffff;
}

/* Media queries */

/* Width */

@media (max-width: 600px) {
  .home-footer-copy-text {
    font-size: 16px;
  }
}

@media (max-width: 1000px) {
  .home-footer-row4-cl {
    align-items: center;
    justify-content: flex-start;
    height: auto;
    margin: 0 !important;
    width: 200px;
    padding-bottom: 20px;
  }

  .home-footer-row3-title {
    letter-spacing: 6px;
    font-size: 20px;
    margin-right: 50px;
    margin-left: 50px;
    text-align: center;
  }

  .home-footer-section-row4 {
    flex-wrap: wrap;
  }

  .home-footer-row4-divider {
    display: none;
  }

  .home-footer-row4-contact {
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
    width: 100%;
  }

  .home-footer-row4-contact {
    align-items: flex-start;
  }

  .home-footer-row4-icon {
    display: none;
  }

  .home-footer-row4-input {
    margin-top: 0 !important;
  }

  .contact-button {
    order: 3 !important;
    padding-bottom: 40px !important;
  }
}
