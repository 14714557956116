.account {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.account-sections {
  height: 100%;
  background-color: transparent !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.account-sections-buttons {
  height: 100px;
  width: 100%;
}

.account-sections-content {
  height: calc(100% - 180px);
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.account-content-wrapper,
.account-contact-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(16, 17, 17);
}

.account-contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.account-contact-text {
  text-align: center;
  width: 80%;
  color: #ffffff;
  margin: 0;
  letter-spacing: 1px;
  margin-left: 15px;
  font-family: "BebasNeueLight";
  font-weight: bolder;
  font-size: 23px;
}

.account-contact-qr-wrapper {
  padding: 15px;
}

.account-contact-qr-image {
  width: 100%;
  height: auto;
}

.account-content {
  width: fit-content;
}

.account-content-user-info {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}

.account-content-user-info-name,
.account-content-user-info-title {
  color: #ffffff;
  margin: 0;
  letter-spacing: 2px;
  margin-left: 15px;
}

.account-content-user-info-name {
  font-family: "BebasNeue";
  font-weight: lighter;
  font-size: 40px;
}

.account-content-user-info-title {
  font-family: "BebasNeueLight";
  font-weight: bolder;
  font-size: 25px;
}

.account-content-table-container {
  height: 300px;
  width: auto;
  width: 400px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-color: red white;
  scrollbar-width: thin;
  background-color: rgba(58, 52, 52, 0.159);
}

.account-content-table {
  border-spacing: 0;
  width: 100%;
  margin-right: 10px;
}

.account-table-row-header {
  font-family: "BebasNeueLight";
  font-weight: bolder;
  font-size: 20px;
  letter-spacing: 2px;
  color: #ffffff;
}

.account-table-row-content {
  font-family: "BebasNeueLight";
  font-weight: bolder;
  font-size: 18px;
  white-space: nowrap;
  letter-spacing: 2px;
  color: #aaa5a5;
}

.account-content-table th,
.account-content-table td {
  text-align: center;
  margin: 0 !important;
  padding: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: 1px solid rgba(48, 48, 48, 0.844);
}

.account-content-table-container::-webkit-scrollbar-track {
  position: fixed !important;
  z-index: 99999 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.account-content-table-container::-webkit-scrollbar {
  position: fixed !important;
  z-index: 99999 !important;
  width: 6px;
  height: 6px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.account-content-table-container::-webkit-scrollbar-thumb {
  position: fixed !important;
  z-index: 99999 !important;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #df0f0f;
}

.account-manage-buttons {
  margin: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.turn-accept-button {
  color: #ffffff !important;
  border-color: #8d8686 !important;
  width: 100px !important;
  height: 35px !important;
}

.take-turn-circular-progress {
  width: 15px !important;
  height: 15px !important;
}

.fetching-turns-wrapper {
  width: 100% !important;
  height: 100% !important;
  margin: 0 auto !important;
}

/* Media queries */

/* Width */

@media (max-width: 1000px) {
  .account {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }

  .account-sections-content {
    height: 150px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .account-content-table-container {
    height: 300px;
    width: auto;
  }
}

@media (max-width: 500px) {
  .account-table-row-header,
  .account-table-row-content {
    font-size: 13px;
  }

  .account-content-table-container {
    overflow-x: auto !important;
    max-width: 100vw;
  }
}

@media (max-width: 410px) {
  td {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}

@media (max-width: 350px) {
  .account-table-row-header,
  .account-table-row-content {
    font-size: 12px;
  }
}

@media (max-width: 310px) {
  .account-table-row-header,
  .account-table-row-content {
    letter-spacing: 1px;
  }
}
