.vehicle-card-detail-grid {
  height: 100%;
  justify-content: center;
  align-items: center;
}

.vehicle-card-open-button {
  padding: 0 !important;
  margin: 15px !important;
}

.vehicle-card-detail-close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 50px;
  height: 50px;
  z-index: 999;
  border-radius: 50%;
  border-color: transparent;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vehicle-card-detail-close-button:hover {
  background-color: rgba(220, 209, 209, 0.775) !important;
}

.vehicle-card-detail-close-icon {
  margin: 0 auto;
  font-size: 40px !important;
  color: rgba(0, 0, 0, 0.775) !important;
  z-index: 9999;
}

.vehicle-card-detail {
  width: 700px;
  height: 600px;
  position: relative;
  border-radius: 10px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: grid;
  grid-template-rows: 25% 75%;
  grid-template-columns: 100%;
  background-color: #ffffff;
}

.vehicle-card-detail-header {
  position: relative;
  grid-row: 1;
  display: flex;
  height: auto;
  width: 100%;
  min-height: 175px;
}

.vehicle-card-detail-header-content {
  width: calc(65% - 30px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 30px;
}

.vehicle-card-detail-header-title-container {
  height: 40px;
  width: 100%;
  margin: 0 !important;
  margin-bottom: 4px !important;
}

.vehicle-card-detail-header-title {
  width: 100%;
  font-family: "BebasNeue" !important;
  font-weight: lighter !important;
  font-size: 40px !important;
  margin: 0 !important;
  margin-bottom: 4px !important;
  color: #000000;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vehicle-card-detail-header-data-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1px;
}

.vehicle-card-detail-header-data-icon {
  height: 25px;
  width: 25px;
  object-fit: contain;
  margin-right: 5px;
  margin-bottom: 3px;
}

.vehicle-card-detail-header-data {
  margin: 0 !important;
  padding: 0 !important;
  font-family: "BebasNeue" !important;
  font-weight: lighter !important;
  color: rgb(67, 63, 63);
  font-size: 23px !important;
  line-height: normal;
  width: auto;
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vehicle-card-detail-header-button {
  height: 100%;
  margin-top: 2px !important;
  margin-bottom: 4px !important;
  width: 28%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vehicle-card-detail-content {
  grid-row: 2;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 245, 245);
  position: relative;
  height: auto;
}

.vehicle-card-detail-shadow {
  -webkit-box-shadow: inset 0px -31px 23px -10px rgba(255, 0, 0, 0.53);
  box-shadow: inset 0px -31px 23px -10px rgba(255, 0, 0, 0.53);
}

.vehicle-card-detail-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 2;
}

.vehicle-card-detail-image {
  height: auto;
  max-height: 100%;
  width: 80%;
  object-fit: contain;
}

.vehicle-card-detail-services-wrapper {
  height: calc(100% - 20px);
  width: 100%;
  position: relative;
  z-index: 3;
  overflow: auto;
  padding: 10px;
  scrollbar-color: red white;
  scrollbar-width: thin;
}

.vehicle-card-detail-services-title {
  font-family: "BebasNeue" !important;
  font-weight: lighter;
  color: #1f1d1d;
  text-shadow: 1px 1px #ffffff;
  font-size: 22px !important;
}

.vehicle-card-detail-service {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  width: fit-content;
}

.additional-service-button {
  cursor: pointer;
}

.vehicle-card-detail-service-decoration {
  width: 6px;
  height: 25px;
  background-color: rgb(242, 33, 33);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.vehicle-card-detail-service-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  padding-right: 10px;
  padding-left: 10px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: rgb(56, 52, 52);
}

.vehicle-card-detail-service-text-wrapper:hover
  .vehicle-card-detail-service-text {
  color: rgb(199, 199, 199) !important;
}

.vehicle-card-detail-service-text {
  font-family: "BebasNeueLight" !important;
  font-weight: bolder;
  color: #ffffff;
  font-size: 18px !important;
  letter-spacing: 1px;
}

.vehicle-card-detail-service-text-icon {
  color: rgb(255, 255, 255);
  margin: 0 auto;
  font-size: 16px !important;
  margin-left: 5px !important;
  -webkit-transition: color 0.5s ease-in;
  -moz-transition: color 0.5s ease-in;
  -o-transition: color 0.5s ease-in;
  transition: color 0.5s ease-in;
}

.vehicle-card-detail-services-wrapper::-webkit-scrollbar-track {
  position: fixed !important;
  z-index: 99999 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.vehicle-card-detail-services-wrapper::-webkit-scrollbar {
  position: fixed !important;
  z-index: 99999 !important;
  width: 6px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.vehicle-card-detail-services-wrapper::-webkit-scrollbar-thumb {
  position: fixed !important;
  z-index: 99999 !important;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #df0f0f;
}

.vehicle-card-detail-additional-services {
  width: 95%;
  max-width: fit-content;
  min-width: 200px;
  margin-top: 75px;
  margin-bottom: 30px;
  height: calc(100% - 105px);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}

.vehicle-card-detail-additional-services-back-button {
  position: absolute !important;
  top: 10px;
  left: 10px;
  background-color: rgba(103, 119, 123, 0.186) !important;
  border-radius: 50% !important;
  width: 50px;
  height: 57px;
  padding: 0 !important;
  margin: 0 !important;
}

.vehicle-card-detail-additional-services-back-icon {
  font-family: "BebasNeue" !important;
  font-weight: lighter;
  color: #1f1d1d !important;
  text-shadow: 1px 1px #ffffff;
  font-size: 24px !important;
  margin: 0 !important;
  padding: 0 !important;
  font-size: 25px !important;
  margin-left: 5px !important;
}

.vehicle-card-detail-additional-services-title {
  text-align: left;
  font-family: "BebasNeue" !important;
  font-weight: lighter;
  color: #1f1d1d;
  text-shadow: 1px 1px #ffffff;
  font-size: 24px !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
}

.vehicle-card-detail-additional-services-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.vehicle-card-detail-additional-services-prices {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
}

.vehicle-card-detail-additional-services-price-container {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 24px);
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.vehicle-card-detail-additional-services-price-text,
.vehicle-card-detail-additional-services-text {
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 21px !important;
  letter-spacing: 1px;
  margin: 0;
  margin-bottom: 0 !important;
  text-shadow: 1px 1px #ffffff;
}

.vehicle-card-detail-additional-services-price-text {
  color: rgb(73, 72, 72);
}

/* Media queries */

/* Width */

@media (max-width: 750px) {
  .vehicle-card-detail {
    width: 100%;
    display: flex;
    border-radius: 0;
    flex-direction: column;
  }

  .vehicle-card-detail-header-button {
    width: 100%;
  }

  .vehicle-card-detail-content {
    height: 100%;
    border-radius: 0;
  }

  .vehicle-card-detail-header {
    margin-top: 50px;
    flex-direction: column;
  }

  .vehicle-card-detail-header-content {
    width: calc(100% - 80px);
  }

  .vehicle-card-detail-header-title-container {
    height: 35px !important;
  }

  .vehicle-card-detail-header-title {
    font-size: 30px !important;
  }

  .vehicle-card-detail-header-data {
    font-size: 20px !important;
  }

  .vehicle-card-detail-header-data-icon {
    height: 16px;
    width: 16px;
    object-fit: contain;
    margin-right: 5px;
  }

  .vehicle-card-detail-header-button {
    justify-content: flex-start;
    align-items: center;
    margin-left: 10px;
    width: calc(100% - 80px);
  }
}

@media (max-width: 450px) {
  .vehicle-card-detail-additional-services-price-text,
  .vehicle-card-detail-additional-services-text {
    font-size: 19px !important;
    letter-spacing: 1px;
  }
}

@media (max-width: 350px) {
  .vehicle-card-open-button {
    padding: 0 !important;
    margin: 0 !important;
    margin-top: 10px !important;
  }

  .vehicle-card-detail-additional-services-price-text,
  .vehicle-card-detail-additional-services-text {
    font-size: 17px !important;
    letter-spacing: 1px;
  }
}
