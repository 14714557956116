.vehicle-card {
  height: 300px;
  width: 250px;
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.vehicle-card:hover .vehicle-card-image-container {
  -webkit-box-shadow: inset 0px -31px 23px -10px rgba(255, 0, 0, 0.53);
  box-shadow: inset 0px -31px 23px -10px rgba(58, 2, 2, 0.53);
}

.vehicle-card-header {
  text-align: left !important;
  height: auto;
  width: calc(100% - 15px);
  margin-left: 15px !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.vehicle-card-header-title {
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 20px !important;
  margin-bottom: 0 !important;
  color: #000000;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vehicle-card-data-text-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 95%;
}

.vehicle-card-data-text {
  margin: 0 !important;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder;
  color: rgb(95, 94, 94);
  font-size: 18px !important;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.vehicle-card-data-text-icon {
  height: 16px;
  width: 16px;
  object-fit: contain;
  margin-right: 5px;
  margin-bottom: 2px;
}

.vehicle-card-icon {
  position: absolute;
  height: 20px;
  top: 2px;
  right: 2px;
}

.vehicle-card-image-container {
  height: 175px;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(245, 245, 245);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: inset 0px -31px 23px -10px rgba(255, 0, 0, 0.53);
  box-shadow: inset 0px -31px 23px -10px rgba(255, 0, 0, 0.53);
}

.vehicle-card-image {
  height: auto;
  max-height: 100%;
  width: 80%;
  object-fit: contain;
}
