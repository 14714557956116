.events {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.events-sections {
  height: 100%;
  background-color: transparent !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.events-sections-header {
  height: 100px;
  width: 100%;
}

.events-sections-content {
  height: calc(100% - 120px);
  width: calc(100% - 80px);
  margin-left: 20px;
  margin-right: 60px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.events-sections-buttons-wrapper {
  width: 410px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.events-sections-name {
  font-family: "BebasNeue";
  font-weight: lighter;
  letter-spacing: 3px;
  font-size: 18px;
  margin: 0;
  text-align: left;
  width: auto !important;
}

.events-sections-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  width: auto;
}

.events-sections-title {
  font-family: "BebasNeue";
  font-weight: bolder;
  font-size: 50px;
  margin: 0;
  margin-bottom: 10px;
  text-align: left;
}

.events-sections-name:hover,
.events-sections-title:hover {
  color: #ffffff !important;
}

.events-content-wrapper {
  height: 100%;
  background-color: rgb(16, 17, 17);
}

.events-content {
  height: 100%;
  width: calc(100% - 60px);
  margin-left: 50px;
  margin-right: 10px;
  overflow-y: auto !important;
  scrollbar-color: red white;
  scrollbar-width: thin;
}

.events-content-info {
  padding-right: 10px;
  width: calc(100% - 100px);
  margin-right: 100px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 200px;
}

.events-content-info-title {
  margin-top: 40px;
  font-family: "BebasNeue";
  font-weight: lighter;
  letter-spacing: 3px;
  font-size: 24px;
  color: rgb(233, 30, 30);
}

.events-content-info-text {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: rgb(255, 255, 255);
  font-size: 15px;
  line-height: 28px;
}

.events-content-info-main {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.events-content-info-text-main {
  font-family: "BebasNeue";
  font-weight: lighter;
  letter-spacing: 3px;
  font-size: 25px;
  margin: 0;
  color: #bab4b4;
  text-align: center;
}

.events-content::-webkit-scrollbar-track {
  position: fixed !important;
  z-index: 99999 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.events-content::-webkit-scrollbar {
  position: fixed !important;
  z-index: 99999 !important;
  width: 6px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.events-content::-webkit-scrollbar-thumb {
  position: fixed !important;
  z-index: 99999 !important;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #df0f0f;
}

/* Media queries */

/* Width */

@media (max-width: 990px) {
  .events {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    overflow-y: visible;
  }

  .events-content {
    height: 100%;
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }

  .events-content-info {
    width: 100%;
    margin-right: 0;
  }

  .events-sections-content {
    height: auto;
    margin-bottom: 50px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .events-sections-buttons-wrapper {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .events-sections-indicators-wrapper {
    width: 250px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .events-sections-title {
    font-size: 35px;
  }

  .events-sections-name {
    font-size: 20px;
  }
}

@media (max-width: 450px) {
  .events-sections-buttons-wrapper {
    width: 315px;
  }

  .events-sections-indicators-wrapper {
    width: 250px;
  }

  .events-sections-name {
    font-size: 17px;
  }
}

@media (max-width: 330px) {
  .events-sections-buttons-wrapper {
    width: 280px;
  }

  .events-sections-indicators-wrapper {
    width: 250px;
  }

  .events-sections-name {
    font-size: 14px;
  }
}
