.chiptuning {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
}

.chiptuning-sections {
  height: 100%;
  background-color: transparent !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.chiptuning-sections-header {
  height: 100px;
  width: 100%;
}

.chiptuning-sections-content {
  height: calc(100% - 180px);
  width: calc(100% - 80px);
  margin-left: 20px;
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chiptuning-sections-buttons-wrapper {
  width: 410px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.chiptuning-sections-name {
  font-family: "BebasNeue";
  font-weight: lighter;
  letter-spacing: 3px;
  font-size: 25px;
  margin: 0;
  text-align: left;
  white-space: nowrap;
}

.chiptuning-sections-button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  width: auto;
}

.chiptuning-sections-title {
  font-family: "BebasNeue";
  font-weight: bolder;
  font-size: 60px;
  margin: 0;
  text-align: left;
}

.chiptuning-sections-name:hover,
.chiptuning-sections-title:hover {
  color: #ffffff !important;
}

.chiptuning-sections-indicators-wrapper {
  margin-top: 40px;
  width: 410px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.chiptuning-sections-indicator-icon {
  width: 5px;
  margin-bottom: 2px;
  margin-right: 6px;
}

.chiptuning-sections-indicator {
  color: #ffffff;
  font-family: "BebasNeue";
  font-weight: lighter;
  letter-spacing: 3px;
  font-size: 20px;
  text-align: center;
  margin: 5px;
  border-bottom: 4px solid #bab4b4;
  width: 50px;
  cursor: pointer;
}

.chiptuning-sections-indicator:hover {
  border-bottom: 4px solid rgb(87, 83, 83) !important;
}

.chiptuning-content-wrapper {
  height: 100%;
  background-color: rgb(16, 17, 17);
}

.chiptuning-content {
  height: 100%;
  width: calc(100% - 60px);
  margin-left: 50px;
  margin-right: 10px;
  overflow-y: auto !important;
  scrollbar-color: red white;
  scrollbar-width: thin;
}

.chiptuning-content-header {
  margin-top: 90px;
}

.chiptuning-content-header-icon {
  width: 50px;
  padding-bottom: 20px;
  border-bottom: 4px solid #f5f5f5;
}

.chiptuning-content-info {
  padding-right: 10px;
  width: calc(100% - 100px);
  margin-right: 100px;
}

.chiptuning-content-info-title {
  margin-top: 40px;
  font-family: "BebasNeue";
  font-weight: lighter;
  letter-spacing: 3px;
  font-size: 24px;
  color: rgb(233, 30, 30);
}

.chiptuning-content-info-text {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  color: rgb(255, 255, 255);
  font-size: 15px;
  line-height: 28px;
}

.chiptuning-content-info-benefit-icon {
  height: 23px;
  width: 23px;
  margin-right: 15px;
}

.chiptuning-content-info-benefit-name {
  font-family: "BebasNeue";
  font-weight: 300;
  color: #f5f5f5;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 1px;
}

.chiptuning-content-info-arrow-icon {
  width: 7px;
  margin-bottom: 2px;
  margin-right: 15px;
}

.chiptuning-content-text-list-title {
  margin-top: 40px;
  font-family: "BebasNeue";
  font-weight: lighter;
  letter-spacing: 2px;
  font-size: 17px;
  color: rgb(233, 30, 30);
}

.chiptuning-content-stagesDSG-text {
  font-family: "BebasNeue";
  font-weight: 300;
  color: #f5f5f5;
  font-size: 19px;
  letter-spacing: 1px;
}

.chiptuning-content-stagesPerformanceTuning-text {
  font-family: "BebasNeue";
  font-weight: 300;
  color: #f5f5f5;
  font-size: 19px;
  letter-spacing: 1px;
}

.chiptuning-content-info-stagesPerformanceTuning-icon-wrapper {
  display: inline-block;
}

.chiptuning-content-info-stagesPerformanceTuning-divisor {
  height: 25px;
  width: 3px;
  margin-left: 5px;
  margin-right: 5px;
}

.chiptuning-content-info-stagesPerformanceTuning-icon {
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.chiptuning-content::-webkit-scrollbar-track {
  position: fixed !important;
  z-index: 99999 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.chiptuning-content::-webkit-scrollbar {
  position: fixed !important;
  z-index: 99999 !important;
  width: 6px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.chiptuning-content::-webkit-scrollbar-thumb {
  position: fixed !important;
  z-index: 99999 !important;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #df0f0f;
}

/* Media queries */

/* Width */

@media (max-width: 990px) {
  .chiptuning {
    grid-template-columns: 100%;
    grid-template-rows: 500px auto;
    overflow-y: visible;
  }

  .chiptuning-content {
    height: 100%;
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }

  .chiptuning-content-info {
    width: 100%;
    margin-right: 0;
  }

  .chiptuning-sections-content {
    height: calc(100% - 180px);
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .chiptuning-sections-buttons-wrapper {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .chiptuning-sections-indicators-wrapper {
    width: 250px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .chiptuning-sections-title {
    font-size: 35px;
  }

  .chiptuning-sections-name {
    font-size: 20px;
  }
}

@media (max-width: 450px) {
  .chiptuning-sections-buttons-wrapper {
    width: 315px;
  }

  .chiptuning-sections-indicators-wrapper {
    width: 250px;
  }

  .chiptuning-sections-name {
    font-size: 17px;
  }
}

@media (max-width: 330px) {
  .chiptuning-sections-buttons-wrapper {
    width: 280px;
  }

  .chiptuning-sections-indicators-wrapper {
    width: 250px;
  }

  .chiptuning-sections-name {
    font-size: 14px;
  }
}
