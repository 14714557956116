.alientech-logo {
  grid-column-start: 1;
  grid-column-end: 2;
  height: 10px !important;
  width: 100px !important;
  margin-left: 57px;
  position: relative;
  z-index: 999;
}

/* Media queries */

/* Width and height */

@media (max-width: 1100px) {
  .alientech-logo {
    display: none;
  }
}
