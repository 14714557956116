.home-section-flex {
  grid-row-start: 2;
  grid-row-end: 3;
}

.home-download-container {
  position: absolute;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  flex-wrap: wrap;
}

.download-container {
  display: flex;
}

.home-mobile-container {
  height: 480px;
  width: 214px;
  margin-right: 25px;
  margin-left: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.home-mobile {
  position: absolute;
  pointer-events: none;
  background-size: contain;
  width: auto;
  height: 100%;
  z-index: 999;
}

.home-mobile-video {
  width: 100% !important;
  height: 95%;
}

.home-download-title {
  line-height: 0.8;
  width: calc(100% - 40px);
  margin-right: 40px;
  text-align: end;
  font-family: "BebasNeue";
  letter-spacing: 1px;
  font-weight: bolder;
  font-size: 60px;
  margin: 0;
  margin-bottom: 10px;
}

.home-download-subtitle {
  line-height: 1;
  width: 100%;
  text-align: start;
  font-family: "BebasNeueLight";
  letter-spacing: 1px;
  font-weight: bold;
  font-size: 24px;
  margin: 0;
  margin-bottom: 10px;
}

.home-download-image {
  cursor: pointer;
  margin-left: 5px;
  width: calc(100% - 5px);
}

.home-download-image:hover {
  filter: opacity(0.8);
}

.home-slider-subtitle {
  padding: 10px;
  overflow-wrap: break-word;
  font-family: "BebasNeueLight";
  letter-spacing: 7px;
  font-weight: 100;
  font-size: 45px;
  margin: 0;
  margin-top: 10px;
}

.home-slider-button {
  margin-top: 80px;
}

.alientech-download-logo {
  height: 10px;
  position: absolute;
  left: 57px;
  bottom: 70px;
}

/* Media queries */

/* Height */

@media (max-height: 750px) {
  .home-mobile-container {
    height: 444px;
    width: 198px;
  }
}

@media (max-height: 690px) {
  .home-mobile-container {
    height: 353px;
    width: 158px;
  }
}

/* Width */

@media (max-width: 1300px) {
  .home-download-subtitle-container {
    display: none;
  }
}

@media (max-width: 800px) {
  .home-download-title {
    width: calc(100%);
    margin-right: 0px;
  }
}

@media (min-width: 400px) and (max-width: 500px) {
  .download-col1 {
    margin-right: 20px;
    margin-left: 20px;
  }
}

@media (max-width: 400px) {
  .download-col1 {
    margin-right: 10px;
    margin-left: 10px;
  }
}

@media (max-width: 1100px) {
  .alientech-download-logo {
    display: none;
  }
}

/* Width and height */

@media (max-width: 1100px), (max-height: 800px) {
  .home-download-container {
    height: calc(100% - 130px);
    padding-right: 25px;
    padding-left: 25px;
    width: calc(100% - 50px);
  }
}

@media (max-width: 700px) and (max-height: 900px) {
  .home-slider-download {
    min-height: 700px;
  }

  .home-mobile-container {
    height: 398px;
    width: 178px;
  }
}
