.home-slider-container {
  position: relative;
  z-index: 2;
  height: 100%;
  display: grid;
  grid-template-rows: 20% 60% 20%;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.home-slider-container-row1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  grid-row-start: 2;
  grid-row-end: 3;
}

.home-slider-container-row2 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 100%;
  height: 100%;
  width: 100%;
  grid-row-start: 3;
  grid-row-end: 4;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.home-slider-container-row2-cl-start,
.home-slider-container-row2-cl-center {
  display: flex;
}

.home-slider-container-row2-cl-start {
  align-items: center;
  justify-content: flex-start;
}

.home-slider-container-row2-cl-center {
  align-items: flex-end;
  justify-content: center;
}

.arrow-down-button {
  margin: 0 auto;
  grid-row-start: 3;
  grid-row-end: 4;
  width: fit-content;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-bottom: 10px;
}

.arrow-down {
  height: 20px;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
}

.arrow-down:hover {
  height: 25px;
}

.home-section-flex {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-section {
  height: 100%;
  width: 100%;
}

.arrow-button {
  height: 20px;
  left: calc(50% - 20px);
  bottom: 3%;
  position: absolute;
}

.arrow-button:hover {
  filter: opacity(0.5);
}

.home-slider-title {
  text-align: center;
  font-family: "BebasNeue";
  letter-spacing: 10px;
  font-weight: 300;
  font-size: 50px;
  margin: 0;
  text-shadow: 2px 2px #000000;
}

.home-slider-subtitle {
  text-align: center;
  padding: 10px;
  overflow-wrap: break-word;
  font-family: "BebasNeueLight";
  letter-spacing: 7px;
  font-weight: 100;
  font-size: 45px;
  margin: 0;
  margin-top: 10px;
  text-shadow: 2px 2px #000000;
}

.home-slider-text {
  text-align: center;
  padding: 10px;
  overflow-wrap: break-word;
  font-family: "BebasNeueLight";
  font-weight: bolder;
  font-size: 25px;
  margin: 0;
  margin-top: 10px;
}

.home-logo {
  position: absolute !important;
  top: 55px;
  left: 40px;
  padding: 10px;
  width: 130px;
}

/* Media queries */

/* Height */

@media (max-height: 850px) {
  .home-slider-container {
    height: calc(100% - 75px) !important;
    margin-top: 75px;
  }
}

/* Width and height */

@media (max-width: 1100px), (max-height: 800px) {
  .arrow-button {
    height: 15px;
    bottom: 2%;
  }
}

@media (max-width: 900px), (max-height: 450px) {
  .home-slider-title {
    font-size: 30px;
  }

  .home-slider-subtitle {
    font-size: 20px;
  }
}
