.login {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-sections {
  height: 100%;
  background-color: transparent !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.login-sections-header {
  height: 100px;
  width: 100%;
}

.login-sections-content {
  height: calc(100% - 180px);
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-content-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(16, 17, 17);
}

.login-form {
  margin-bottom: 30px;
}

.login-form,
.login-form-inputs {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-form-inputs {
  margin-bottom: 50px;
}

.login-content-wrapper .login-form-item {
  max-width: 300px !important;
}

.login-form-item-email {
  width: 300px !important;
}

.login-form-item label {
  margin-top: 20px;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 25px !important;
  letter-spacing: 1px;
  color: #ffffff !important;
}

.login-form-item input {
  margin-top: 20px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
  font-weight: lighter !important;
  font-size: 25px !important;
  letter-spacing: 0px;
  color: #ffffff !important;
  width: 100% !important;
}

.login-form-item .MuiSvgIcon-root {
  color: #ffffff !important;
}

/* Media queries */

/* Width */

@media (max-width: 990px) {
  .login {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    overflow-y: visible;
  }

  .login-sections-content {
    height: 150px;
    width: calc(100% - 40px);
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 400px) {
  .login-form-item {
    width: 100%;
  }

  .login-content-wrapper .login-form-item {
    max-width: 280px !important;
  }

  .login-form-item label {
    font-size: 20px !important;
    letter-spacing: 1px;
  }

  .login-form-item input {
    font-size: 20px !important;
    letter-spacing: 0px;
  }

  .login-content-wrapper .login-form-item {
    max-width: 250px !important;
  }
}
