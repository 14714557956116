.gallery-button {
  margin-bottom: 20px;
}

.react-multi-carousel-dot-list {
  overflow-x: auto !important;
  overflow-y: hidden !important;
  left: 0 !important;
  padding-block-start: 0 !important;
  white-space: nowrap !important;
  display: inline !important;
}

.dot-img-container {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  height: auto;
  width: auto;
  margin-right: 5px;
  margin-left: 5px;
  filter: contrast(0.5);
  margin-bottom: 20px;
  min-width: fit-content;
}

.dot-img-container-active {
  filter: contrast(1);
}

.dot-img {
  background-color: #ffffff;
  border-radius: 10px !important;
  height: 60px;
  max-width: 80px;
  width: auto;
  object-fit: contain;
}

.gallery-close-button {
  position: absolute;
  top: 55px;
  right: 45px;
  width: 50px;
  height: 50px;
  z-index: 999;
  border-radius: 50%;
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-close-button:hover {
  background-color: rgba(0, 0, 0, 0.775) !important;
}

.gallery-close-icon {
  margin: 0 auto;
  font-size: 40px !important;
  color: #ffffff !important;
  z-index: 9999;
}

.carousel-gallery-grid {
  height: 100%;
  justify-content: center;
  align-items: center;
}

.carousel-gallery-container {
  width: 100%;
  height: 600px;
}

.carousel-gallery-item {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.carousel-gallery-item-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 400px;
  margin-right: 20px;
  margin-left: 20px;
}

.carousel-gallery-item-img {
  height: 100%;
  max-height: 380px;
  max-width: 100%;
  width: auto;
  object-fit: contain;
  border-radius: 20px !important;
}

/* Media queries */

/* Width */

@media (max-width: 400px) {
  .dot-img-container {
    width: 60px;
  }

  .carousel-gallery-container {
    height: 500px;
  }
}

/* Width and height */

@media (max-width: 990px), (max-height: 450px) {
  .gallery-close-button {
    top: 10px !important;
    right: 55px;
  }
}
