.alientech-slider-container {
  grid-template-rows: 20% 60% 20% !important;
}

.carousel-06-container {
  width: 70%;
  display: grid;
}

.alientech-carousel {
  min-height: 350px;
}

.carousel-06-item {
  text-align: center;
  width: 650px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.carousel-06-item-img-container {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-06-item-img-link {
  height: 100%;
}

.carousel-06-item-img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: contain;
}

.home-06-alientech {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.alientech-06-logo {
  height: 30px;
  margin-right: 20px;
}

.home-06-button {
  background-color: #29262669;
  color: #ffffff;
  border: 0;
  padding: 7px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 30px;
  font-family: "BebasNeue";
  font-weight: 100;
  font-size: 18px;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  position: relative;
  z-index: 99999999;
}

.home-06-button:hover {
  background-color: #7e7a7a3c;
}

.home-06-button-arrow {
  height: 15px;
  margin-left: 10px;
}

.home-06-button-carousel {
  position: absolute;
  bottom: 0;
  background: #161616;
  color: #ffffff;
  border: 1px solid #ffffff;
  padding: 7px;
  padding-right: 20px;
  padding-left: 20px;
  border-radius: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  letter-spacing: 1px;
  font-weight: 600;
  font-size: 18px;
  margin: 0;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  -o-transition: all 0.5s linear;
  transition: all 0.5s linear;
  z-index: 99999999;
}

.home-06-button-carousel:hover {
  background-color: #ffffff;
  color: #000000;
}

/* Media queries */

/* Width */

@media (max-width: 550px) {
  .home-06-alientech {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .alientech-06-logo {
    height: 15px;
    margin-bottom: 15px;
  }
}
