.mdq-footer {
  background-color: #000000;
}

.mdq-footer-content {
  height: 300px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.mdq-footer-content-item {
  display: flex;
  justify-content: center;
  width: 300px;
}

.mdq-footer-content-logo {
  width: 130px;
}

.mdq-button-wrapper {
  padding-right: 10px;
  padding-left: 10px;
}

.mdq-footer-contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0;
  text-align: left !important;
}

.mdq-footer-title {
  color: #c9c1c1;
  font-family: "BebasNeue";
  font-weight: 300;
  font-size: 23px;
  margin: 0 !important;
}

.mdq-footer-text {
  color: #ffffff;
  overflow-wrap: break-word;
  font-family: "BebasNeueLight";
  letter-spacing: 1px;
  font-weight: 100;
  font-size: 20px;
  margin: 0 !important;
}

.mdq-footer-icon {
  width: 30px;
  margin-right: 30px;
}
