.we-section {
  height: 100%;
  width: 100%;
  z-index: 2;
  position: absolute !important;
}

.we-container {
  position: relative;
  z-index: 2;
  height: 100vh;
  display: grid;
  grid-template-rows: 20% 60% 20%;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.we-container-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  grid-row-start: 2;
  grid-row-end: 3;
}

.we-section-title {
  text-align: center;
  font-family: "BebasNeue";
  letter-spacing: 10px;
  font-weight: 300;
  font-size: 50px;
  margin: 0;
  margin-bottom: 10px;
  text-shadow: 2px 2px #000000;
}

.we-section-subtitle {
  text-align: center;
  padding: 10px;
  overflow-wrap: break-word;
  font-family: "BebasNeueLight";
  letter-spacing: 1px;
  font-weight: bolder;
  font-size: 35px;
  margin: 0;
  margin-top: 10px;
  text-shadow: 2px 2px #000000;
}

.we-team-to-team-button {
  margin: 0 auto;
  grid-row-start: 3;
  grid-row-end: 4;
  width: fit-content;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.we-team-to-team-button:hover .we-team-to-team {
  height: 30px;
}

.we-team-to-team {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  height: 20px;
}

.we-team-container {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;
  background-color: rgb(24, 25, 26);
}

.we-team-container-bg-lines {
  position: absolute;
}

.we-team-car {
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: 0;
}

/* Media queries */

/* Width and height */

@media (max-width: 900px), (max-height: 450px) {
  .we-section-title {
    font-size: 35px;
  }

  .we-section-subtitle {
    font-size: 28px;
  }
}
