.mdq-logo {
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in;
  z-index: 3;
  position: fixed !important;
  top: 55px;
  left: 40px;
  padding: 10px;
  width: 130px;
}

/* Media queries */

/* Width */

@media (max-width: 450px) {
  .mdq-logo {
    left: 20px !important;
  }
}

/* Width and height */

@media (max-width: 990px), (max-height: 600px) {
  .mdq-logo {
    top: 10px !important;
    left: 40px;
  }
}
