@font-face {
  font-family: "BebasNeue";
  src: url("/public/assets/fonts/bebas_neue.ttf");
}

@font-face {
  font-family: "BebasNeueLight";
  src: url("/public/assets/fonts/bebas_neue_light.ttf");
}

* {
  outline: 0 !important;
  -webkit-tap-highlight-color: transparent;
}

html {
  overflow-x: hidden !important;
  overflow-y: auto;
  margin-top: 0 !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100%;
}

body {
  height: 100vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  -webkit-transition: background-image 0.1s ease;
  -moz-transition: background-image 0.1s ease;
  -o-transition: background-image 0.1s ease;
  transition: background-image 0.1s ease;
  background-color: rgb(4, 4, 4);
}

#root {
  height: 100vh;
  display: grid;
  grid-template-columns: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-overflow-scrolling: touch;
  min-height: 550px;
}

a {
  text-decoration: none;
}

.default-small-button,
.default-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.default-button {
  position: relative;
  width: 270px !important;
  height: 60px !important;
  border-color: transparent;
  background-image: url("/public/assets/buttons/default-button-border.svg");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  background-color: rgba(0, 0, 0, 0.159);
  border-radius: 40px;
  color: #ffffff;
  font-family: "BebasNeue";
  letter-spacing: 1px;
  cursor: pointer;
}

.default-button span {
  font-weight: 300;
  font-size: 25px;
  background-color: transparent !important;
}

.default-small-button {
  margin: 15px;
  border: 1px solid grey;
  position: relative;
  width: 200px;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.159);
  border-radius: 40px;
  color: #ffffff;
  font-family: "BebasNeue";
  font-size: 20px;
  letter-spacing: 1px;
  cursor: pointer;
}

.default-red-button {
  margin: 15px;
  border: 1px solid rgb(255, 255, 255);
  position: relative;
  width: 200px;
  height: 50px;
  background-color: rgb(192, 22, 22);
  border-radius: 40px;
  color: #ffffff;
  font-family: "BebasNeue";
  font-size: 22px;
  letter-spacing: 1px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.default-red-button:hover {
  background-color: rgb(126, 18, 18);
}

.default-button:hover,
.default-small-button:hover {
  background-color: transparent;
  color: rgb(206, 199, 199);
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.topbar-overlay {
  pointer-events: none !important;
  height: 70px;
  width: 100%;
  position: fixed;
  z-index: 3;
  top: 0;
  background-color: transparent;
  -webkit-transition: all 300ms ease-out;
  -moz-transition: all 300ms ease-out;
  -o-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}

.MuiBackdrop-root {
  background-color: rgba(0, 0, 0, 0.933) !important;
}

.home-02-row1 .react-multiple-carousel__arrow {
  width: 35px !important;
  height: 35px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px !important;
}

.home-02-row1 .react-multiple-carousel__arrow::before {
  font-size: 28px !important;
}

.mdq-dialog-button {
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 19px !important;
  color: red !important;
}

.mdq-dialog-title {
  font-family: "BebasNeue" !important;
  font-weight: lighter !important;
  font-size: 30px !important;
  letter-spacing: 1px !important;
  color: rgb(34, 32, 32) !important;
}

.mdq-dialog-data {
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  letter-spacing: 1px !important;
  color: rgb(49, 47, 47) !important;
  font-size: 19px !important;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.mdq-dialog-data-title-container {
  height: 30px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: 5px;
}

.mdq-dialog-data-title {
  overflow-wrap: normal !important;
  word-wrap: normal !important;
  color: grey;
}

.mdq-dialog-icon {
  color: red;
  font-size: 19px !important;
  margin-right: 5px;
  margin-bottom: 3px;
}

.MuiPaginationItem-root {
  color: rgb(255, 255, 255) !important;
  font-size: 18px !important;
}

.MuiPaginationItem-page {
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
}

.MuiPagination-ul .Mui-selected {
  background-color: rgba(208, 190, 190, 0.296) !important;
}

.default-divider {
  width: 100%;
  margin-bottom: 10px !important;
}

.date-picker-dialog {
  margin-top: 15px !important;
}

.date-picker-dialog .MuiFormControl-root {
  margin-top: 10px !important;
  margin-right: 20px !important;
  margin-left: 20px !important;
  width: 170px !important;
}

.date-picker-dialog input,
.date-picker-dialog label {
  color: black !important;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 18px !important;
  letter-spacing: 1px !important;
}

.gm-style-pbc {
  z-index: 999 !important;
  pointer-events: none;
}

.tooltip-text {
  font-size: 20px;
  font-family: "BebasNeueLight" !important;
  letter-spacing: 1px !important;
  font-weight: bolder !important;
  margin: 2px !important;
}

/* Media queries */

/* Width */

@media (min-width: 990px) {
  .react-multiple-carousel__arrow {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .react-multiple-carousel__arrow::before {
    font-size: 48px !important;
  }
}

/* Width and height */

@media (max-width: 1100px), (max-height: 600px) {
  body {
    background-size: auto;
  }
}

@media (max-width: 850px), (max-height: 450px) {
  body {
    background-size: auto;
    background-position: top;
  }
}

@media (max-height: 650px) {
  body {
    background-size: auto;
    background-position: top;
  }
}
