.team-section-card {
  position: relative;
  z-index: 2;
  padding-top: 100px;
  padding-bottom: 40px;
}

.team-section-card-name {
  text-align: center;
  font-family: "BebasNeue";
  letter-spacing: 10px;
  font-weight: 300;
  font-size: 50px;
  margin: 0;
  margin-bottom: 10px;
  color: #ffffff;
  text-shadow: 2px 2px rgb(24, 25, 26);
  padding-bottom: 50px;
}

.team-section-card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

/* Media queries */

/* Width */

@media (max-width: 600px) {
  .team-section-card-name {
    font-size: 30px;
  }
}
