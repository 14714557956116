.signin-form-item label {
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 25px !important;
  letter-spacing: 1px;
  color: #ffffff !important;
}

.signin-form-item {
  width: 100%;
  margin-bottom: 20px !important;
}

.signin-button {
  color: #ffffff !important;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
}

.signin-button:hover {
  color: rgba(255, 255, 255, 0.645) !important;
  background-color: transparent !important;
}

.signin-submit-button {
  color: black !important;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
}

.signin-submit-button:hover {
  color: rgb(255, 0, 0) !important;
  background-color: transparent !important;
}

.signin-dialog .MuiInput-input,
.signin-dialog .MuiFormLabel-root,
.MuiInput-label {
  font-size: 20px !important;
  color: rgb(34, 32, 32) !important;
}

.signin-dialog .MuiInput-underline::before {
  border-bottom-color: black !important;
  border-bottom: 1px solid !important;
}
