.home-02-row1 {
  height: 300px;
}

.carousel-02-container {
  width: 80%;
  display: grid;
}

.carousel-02-item {
  text-align: center;
  width: 250px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.carousel-02-item-text-container {
  font-family: "BebasNeue";
  height: 60% !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.carousel-02-item-img-container {
  height: 40% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-02-item-img-link {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-02-item-img {
  width: 100%;
  height: auto;
  max-height: 95%;
  object-fit: contain;
}

.carousel-02-item-text-container-title {
  font-size: 25px;
  font-weight: lighter;
  letter-spacing: 1px;
  margin: 0;
}

.carousel-02-item-text-container-desc {
  font-size: 18px;
  font-weight: lighter;
  letter-spacing: 1px;
  color: #d0c7c7;
  margin: 0;
  margin-top: 5px;
}

.react-multi-carousel-item {
  display: flex !important;
  justify-content: center !important;
  justify-items: center !important;
  align-items: center !important;
}

.home-slider-title {
  font-family: "BebasNeue";
  letter-spacing: 10px;
  font-weight: 300;
  font-size: 50px;
  margin: 0;
}

.home-slider-subtitle {
  padding: 10px;
  overflow-wrap: break-word;
  font-family: "BebasNeueLight";
  letter-spacing: 7px;
  font-weight: 100;
  font-size: 45px;
  margin: 0;
  margin-top: 10px;
}

.home-slider-button {
  margin-top: 80px;
}

.arrow-button-wrapper {
  cursor: pointer;
}

/* Media queries */

/* Height */

@media (max-height: 400px) {
  .home-slider-button {
    margin-top: 20px;
  }
}

/* Width and height */

@media (max-width: 700px), (max-height: 450px) {
  .home-slider-title {
    font-size: 30px;
  }

  .home-slider-subtitle {
    font-size: 15px;
  }
}
