.team-card {
  height: 550px;
  width: 300px;
  background-color: #ffffff;
  font-family: "BebasNeue";
  display: grid;
  grid-template-rows: 50px 350px 150px;
  border-radius: 20px;
  border: 1px solid #616161;
  margin: 3px;
}

.team-card-header {
  justify-content: space-between;
  align-items: flex-end;
  display: flex;
  grid-row-start: 0;
  grid-row-start: 1;
  letter-spacing: 3px;
  margin-bottom: 3px;
}

.team-card-header-content {
  display: flex;
}

.mdq-logo-card {
  margin-left: 10px;
  height: 20px;
  padding: 0 !important;
}

.mdq-logo-card-text {
  margin-left: 5px;
  text-align: end;
  font-size: 20px;
}

.mdq-icon-card {
  height: 35px;
}

.team-card-image-wrapper {
  position: relative;
  z-index: 4;
  grid-row-start: 1;
  grid-row-start: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 350px;
}

.team-card-image-wrapper-circle {
  position: relative;
  z-index: 2;
  height: 200px;
  width: 200px;
  border-radius: 50%;
  background-color: red;
  position: relative;
}

.team-card-image-wrapper-ring {
  position: absolute;
  z-index: 2;
  height: 230px;
  width: 223px;
  border-radius: 50%;
  background-color: transparent;
  border: 2px solid red;
}

.team-card-image-member {
  height: 100%;
  width: auto;
  object-fit: contain;
  position: absolute;
  z-index: 3;
}

.team-card-data {
  -webkit-box-shadow: 0px -46px 26px -18px rgba(255, 255, 255, 0.85);
  box-shadow: 0px -46px 26px -18px rgba(255, 255, 255, 0.85);
  background-color: #ffffff;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  position: relative;
  z-index: 5;
  width: 300px;
  grid-row-start: 2;
  grid-row-start: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.team-card-data-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 300px;
  font-size: 28px;
  color: rgb(43, 41, 41);
  margin: 0;
  padding: 0;
}

.team-card-data-position {
  white-space: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  font-size: 28px;
  color: rgb(43, 41, 41);
  font-family: "BebasNeueLight";
  font-weight: bolder;
  font-size: 20px;
  margin: 0;
  padding: 0;
}

.team-card-contact {
  position: relative;
  z-index: 5;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.782);
  border-radius: 18px;
  height: 550px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  visibility: hidden;
}

.team-card-contact-icon {
  width: 50px;
  margin-bottom: 30px;
}

.team-card-contact-text {
  margin: 0;
  color: #ffffff;
  font-family: "BebasNeue";
  font-size: 27px;
}
