.vehicle-search {
  margin-top: 150px;
}

.vehicle-search-bg-lines {
  top: 0;
  position: absolute;
  z-index: -1;
}

.vehicle-search-content-container {
  width: 100%;
  display: grid;
  grid-template-columns: 360px auto;
  margin-bottom: 100px;
}

.vehicle-search-filters {
  grid-column: 1;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  padding: 10px;
}

.vehicle-search-filters-header {
  width: 100% !important;
}

.vehicle-search-filters-header-title {
  margin: 0 auto;
  width: 233px !important;
  color: #ffffff;
  font-family: "BebasNeue";
  font-weight: bolder;
  font-size: 60px;
  text-align: left;
  line-height: 0.9;
}

.vehicle-search-filters-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 15px;
}

.vehicle-search-filters-formcontrol,
.vehicle-search-filters-input {
  width: 230px !important;
  margin-bottom: 8px !important;
}

.vehicle-search-filters-formcontrol-label,
.vehicle-search-filters-formcontrol-select,
.vehicle-search-filters-formcontrol-item {
  font-family: "BebasNeue" !important;
  font-size: 18px !important;
}

.vehicle-search-filters-formcontrol .MuiFormHelperText-root {
  color: #9b9797 !important;
}

.vehicle-search-filters-input {
  margin-bottom: 10px !important;
}

.vehicle-search-filters-input label,
.vehicle-search-filters-input .MuiInputBase-root,
.vehicle-search-filters-input input {
  width: 100% !important;
  font-family: "BebasNeue" !important;
  font-size: 18px !important;
}

.vehicle-search-filters-search {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vehicle-search-data {
  grid-column: 2;
  width: calc(100% - 20px);
  height: calc(100% - 120px);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
}

.vehicle-search-header {
  width: 100%;
}

.vehicle-search-empty-list {
  font-family: "BebasNeue" !important;
  font-size: 22px !important;
  margin-bottom: 10px;
  color: #ffffff;
}

.vehicle-search-pagination {
  margin-top: 15px;
}

/* Media queries */

/* Width  */

@media (max-width: 960px) {
  .vehicle-search-content-container {
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    margin-bottom: 100px;
  }

  .vehicle-search-filters-header-title {
    width: 80% !important;
    text-align: center;
  }

  .vehicle-search-data {
    grid-column: 1;
  }
}

/* Width and height  */

@media (max-width: 990px), (max-height: 600px) {
  .vehicle-search {
    margin-top: 85px;
  }
}
