.home-01-carousel {
  position: absolute;
}

.home-01-carousel-item,
.home-01-carousel,
.home-01-carousel .carousel-slider,
.home-01-carousel .slider,
.home-01-carousel .slider-wrapper,
.home-01-carousel .slide {
  width: 100%;
  height: 100%;
}

.home-01-carousel-item {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Media queries */

/* Width and height */

@media (max-width: 700px), (max-height: 450px) {
  .title-01 {
    font-size: 35px !important;
  }

  .subtitle-01 {
    font-size: 30px !important;
    margin-right: 30px !important;
    margin-left: 30px !important;
  }
}
