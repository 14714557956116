.branches {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(16, 17, 17);
}

.branches-content {
  height: 100%;
  background-color: rgb(16, 17, 17);
}

.branches-content-header {
  height: 100px;
  width: 100%;
}

.branches-content-data {
  height: calc(100% - 180px);
  width: calc(100% - 40px);
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: #ffffff;
}

.branches-content-data-title {
  font-family: "BebasNeue" !important;
  font-weight: bolder !important;
  font-size: 40px !important;
  letter-spacing: 1px;
  margin-bottom: 0;
}

.branches-content-data-text {
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 20px !important;
  letter-spacing: 1px;
}

.branches-content-data-counter-wrapper {
  margin: 0 auto;
  margin-top: 30px !important;
  height: 80px;
}

.branches-content-data-counter-number,
.branches-content-data-counter-text {
  text-align: center;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
}

.branches-content-data-counter-number {
  font-size: 30px !important;
  letter-spacing: 1px;
  margin: 0;
  color: grey;
}

.branches-content-data-counter-text {
  font-size: 25px !important;
  letter-spacing: 1px;
  margin: 0;
}

.branches-select {
  width: 300px !important;
}

.MuiSelect-icon {
  color: #ffffff !important;
}

.branches-map {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(16, 17, 17);
}

.mdq-branch-logo-container {
  position: absolute;
  transform: translate(-50%, -50%);
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 35px !important;
  height: 35px !important;
  background-color: rgb(16, 17, 17);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 50%;
  cursor: pointer;
}

.mdq-branch-logo-container:hover {
  background-color: grey;
}

.mdq-branch-logo {
  width: 100%;
  height: 100%;
}

/* Media queries */

/* Width */

@media (max-width: 990px) {
  .branches {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    overflow-y: visible;
  }

  .branches-map {
    height: 450px !important;
  }
}
