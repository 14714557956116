.home-slider-content {
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 15% 70% 15%;
  width: 80%;
}

/*CAROUSEL*/

.carousel-05-container {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel-05 {
  width: 100%;
  height: 100%;
}

.carousel-05-item {
  text-align: center;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: center;
  align-items: center;
  height: 250px !important;
}

/*SPECS*/

.carousel-05-specs {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  overflow-wrap: nowrap;
  font-family: "BebasNeueLight";
  font-weight: 100;
  font-size: 25px;
}

.carousel-05-specs-wrapper {
  width: auto;
  display: flex;
  justify-content: left;
  margin-bottom: 5px;
}

.carousel-05-specs-title {
  font-size: 25px;
  text-align: left;
  margin: 0 !important;
  margin-left: 5px !important;
  margin-bottom: 10px !important;
}

.carousel-05-specs-text {
  font-size: 25px;
  margin: 0;
  width: 100%;
  text-align: left;
  max-width: 250px;
}

.carousel-05-specs-icon {
  height: 30px !important;
  width: 30px !important;
  margin-right: 10px !important;
}

/*IMAGE*/

.carousel-05-item-img-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  max-width: max-content;
  height: 220px;
  padding: 20px;
}

.carousel-05-item-img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

/* Media queries */

/* Width */

@media (max-width: 1600px) {
  .home-slider-content {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: 3% 94% 3%;
    width: 80%;
  }
}

@media (max-width: 1200px) {
  .carousel-05-item {
    grid-template-columns: 100%;
    height: auto !important;
  }

  .carousel-05-container {
    height: auto !important;
    margin-top: 0px;
    grid-column-start: 2;
    grid-column-end: 3;
    grid-row-start: 1;
    grid-row-end: 2;
  }

  .carousel-05-specs-wrapper {
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .carousel-05-specs {
    align-items: center;
  }

  .carousel-05-specs-text {
    width: auto;
    text-align: left;
  }

  .carousel-05-specs-title {
    text-align: center !important;
  }

  .carousel-05-item-img-container {
    margin: 0 auto;
    width: 100% !important;
    padding: 0;
  }

  .carousel-05-item-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

@media (max-width: 800px) {
  .lastjobs-text {
    font-size: 17px;
  }
}

@media (max-width: 390px) {
  .lastjobs-title {
    letter-spacing: 7px;
    font-size: 22px;
  }
}

/* Width and height*/

@media (max-width: 800px) and (max-height: 900px) {
  .lastjobs-text {
    font-size: 17px;
  }
}

@media (max-width: 1200px) and (max-height: 650px) {
  .lastjobs-section {
    min-height: 700px;
  }
}
