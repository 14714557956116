.reseller-form-wrapper {
  margin: 0 auto;
  background-color: #ffffff;
  padding-top: 10px;
  border-radius: 20px;
  width: 750px;
  height: 600px;
}

.reseller-form-wrapper-header {
  width: 80%;
  height: 100px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.reseller-form-wrapper-title {
  width: 60%;
  margin: 0 !important;
  margin-top: 15px !important;
  padding: 0 !important;
  font-family: "BebasNeue" !important;
  font-weight: lighter !important;
  font-size: 40px !important;
  letter-spacing: 1px !important;
  color: #1b1a1a !important;
}

.reseller-form-wrapper-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.reseller-form-wrapper-content {
  height: 500px;
  width: 100%;
  background-color: rgb(233, 229, 229);
  -webkit-box-shadow: inset 0px -15px 23px -10px rgba(255, 0, 0, 0.53);
  box-shadow: inset 0px -15px 23px -10px rgba(255, 0, 0, 0.53);
  border-radius: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
}

.reseller-form-info {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 55%;
  height: 400px;
  padding-right: 30px;
  padding-left: 30px;
}

.reseller-form-info-text {
  font-family: "BebasNeue" !important;
  font-weight: lighter !important;
  font-size: 25px;
  letter-spacing: 1px !important;
  color: #525050 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.reseller-form-info-text-small {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder !important;
  font-size: 10px !important;
  color: #525050 !important;
  margin-top: 10px;
  margin-bottom: 30px;
}

.reseller-form-captcha-wrapper {
  height: 100px;
}

.g-recaptcha-required-text {
  font-size: 10px !important;
  color: #f44336;
  margin-top: 3px;
  text-align: left;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.reseller-form-inputs {
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.reseller-form-inputs div {
  width: 90%;
}

.reseller-form-bg-lines {
  position: absolute;
  top: 0;
  width: 100%;
  filter: opacity(0.5);
  z-index: -1;
}

.reseller-form-item label {
  font-family: "BebasNeue" !important;
  font-weight: lighter !important;
  font-size: 16px !important;
  letter-spacing: 0px !important;
  color: #767676 !important;
}

.reseller-form-item {
  width: 100%;
  height: 70px;
}

.reseller-form-item input {
  color: rgb(29, 28, 28) !important;
  font-size: 13px !important;
}

.reseller-form-item p {
  font-size: 10px !important;
}

.reseller-button {
  color: #ffffff !important;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
}

.reseller-button:hover {
  color: rgba(255, 255, 255, 0.645) !important;
  background-color: transparent !important;
}

.reseller-submit-button {
  color: black !important;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
}

.reseller-submit-button:hover {
  color: rgb(255, 0, 0) !important;
  background-color: transparent !important;
}

.reseller-dialog .MuiInput-input,
.reseller-dialog .MuiFormLabel-root,
.MuiInput-label {
  font-size: 18px !important;
  color: rgb(34, 32, 32) !important;
}

.reseller-dialog .MuiInput-underline::before {
  border-bottom-color: black !important;
  border-bottom: 1px solid !important;
}

/* Media queries */

/* Width */

@media (max-width: 850px) {
  .reseller-form-wrapper {
    height: auto;
    width: 80%;
  }

  .reseller-form-wrapper-content {
    flex-wrap: wrap;
    height: auto;
  }

  .reseller-form-captcha-wrapper {
    height: 150px !important;
  }

  .reseller-form-info {
    width: 100%;
    height: auto;
    min-height: auto;
    padding-bottom: 15px;
  }

  .reseller-form-info-text {
    height: auto;
    font-size: 17px;
  }

  .reseller-form-inputs {
    width: 80% !important;
    padding-bottom: 15px;
    margin: 0 auto;
  }

  .reseller-form-inputs div {
    width: 100%;
  }

  .reseller-form-wrapper-header {
    height: 150px;
    flex-wrap: wrap;
  }

  .reseller-form-wrapper-title {
    width: 100%;
  }

  .reseller-form-wrapper-button {
    width: 100%;
    justify-content: flex-start;
  }
}

@media (max-width: 450px) {
  .reseller-form-wrapper {
    width: 90%;
  }

  .reseller-form-info-text {
    font-size: 15px;
  }
}

@media (max-width: 400px) {
  .reseller-form-captcha {
    height: 80px !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

/* Heigth */

@media (max-height: 1300px) {
  .reseller-form-wrapper {
    margin-top: 130px;
    margin-bottom: 30px;
  }
}
