.set-turn {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(16, 17, 17);
}

.set-turn-content {
  margin-top: 150px;
  height: calc(100% - 150px);
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}

.set-turn-content-form {
  background-color: #ffffff;
  border-radius: 20px;
  width: 80%;
  height: auto;
}

.set-turn-content-form-branch-data {
  height: 100%;
  max-height: 120px !important;
  width: calc(100% - 60px);
  margin-left: 20px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
  padding-bottom: 20px;
}

.set-turn-content-form-branch-content {
  width: 100%;
  height: 100%;
  max-height: 300px !important;
  border-radius: 20px;
  background-color: rgb(220, 215, 215);
  -webkit-box-shadow: inset 0px -31px 23px -10px rgba(255, 0, 0, 0.53);
  box-shadow: inset 0px -31px 23px -10px rgba(255, 0, 0, 0.53);
  padding-bottom: 20px;
}

.set-turn-content-form-branch-content-turn {
  height: auto;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.set-turn-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.set-turn-text-input {
  margin-top: 10px !important;
}

.set-turn-button {
  margin-top: 30px !important;
}

.set-turn-down-button {
  margin: 5px !important;
  color: rgb(192, 22, 22) !important;
  padding: 0 !important;
  min-width: 35px !important;
  min-height: 35px !important;
  border-radius: 50% !important;
}

.set-turn-formcontrol,
.set-turn-input {
  width: 230px !important;
}

.set-turn-formcontrol-label,
.set-turn-formcontrol-select,
.set-turn-formcontrol-item {
  font-family: "BebasNeue" !important;
  font-size: 18px !important;
  margin-bottom: 10px;
}

.set-turn-formcontrol-label,
.set-turn-formcontrol .MuiInput-input {
  color: black !important;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 18px !important;
  letter-spacing: 1px !important;
}

.set-turn-text-input input,
.set-turn-text-input label {
  color: black !important;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  letter-spacing: 1px !important;
  font-size: 18px !important;
  width: 170px !important;
}

.set-turn-content-form-data-text-help {
  text-align: center;
  margin-top: 15px !important;
  margin-bottom: 5px !important;
  font-family: "BebasNeue" !important;
  font-weight: lighter !important;
  font-size: 15px !important;
  letter-spacing: 1px;
  margin: 0;
}

.set-turn-content-form-data-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 15px;
  width: 250px;
  height: 65px;
}

.set-turn-content-form-data-alert-text {
  color: #2c2a2a;
  font-family: "BebasNeue";
  font-size: 22px;
  letter-spacing: 1px;
}

.set-turn-content-form-data-title {
  font-family: "BebasNeue" !important;
  font-weight: bolder !important;
  font-size: 30px !important;
  letter-spacing: 1px;
  margin-bottom: 0;
  color: black;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.set-turn-content-form-data-text {
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 18px !important;
  letter-spacing: 1px;
  margin: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 100%;
}

.set-turn-select {
  width: 300px !important;
}

.MuiSelect-icon {
  color: #ffffff !important;
}

.set-turn-map {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(16, 17, 17);
}

.mdq-set-turn-logo-container {
  position: absolute;
  transform: translate(-50%, -50%);
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
  width: 35px !important;
  height: 35px !important;
  background-color: rgb(16, 17, 17);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
  border-radius: 50%;
  cursor: pointer;
}

.mdq-set-turn-logo-container:hover {
  background-color: grey;
}

.mdq-set-turn-logo {
  width: 100%;
  height: 100%;
}

/* Media queries */

/* Width */

@media (max-width: 990px) {
  .set-turn {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
    overflow-y: visible;
  }

  .set-turn-content-form-branch-data {
    max-height: 100% !important;
  }

  .set-turn-map {
    height: 450px !important;
  }

  .set-turn-content {
    margin-bottom: 70px;
    height: calc(100% - 230px);
  }

  .set-turn-content-form-data-title {
    white-space: pre-wrap;
  }
}

@media (max-width: 550px) {
  .set-turn-content-form {
    width: 85%;
  }
}

@media (max-width: 400px) {
  .set-turn-content-form {
    width: 95%;
  }
}

/* Width and height */

@media (max-height: 680px) and (min-width: 990px) {
  .set-turn-content {
    margin-top: 70px;
    height: calc(100% - 70px);
  }
}
