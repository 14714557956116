.contact-form-wrapper {
  margin: 0 auto;
  background-color: #ffffff;
  padding-top: 10px;
  border-radius: 20px;
  width: 700px;
}

.contact-form-wrapper-header {
  width: 80%;
  margin: 0 auto;
  display: flex;
}

.contact-form-wrapper-title {
  width: 60%;
}

.contact-form-wrapper-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
}

.contact-form-wrapper-content {
  background-color: rgb(233, 229, 229);
  -webkit-box-shadow: inset 0px -23px 23px -10px rgba(255, 0, 0, 0.53);
  box-shadow: inset 0px -20px 23px -10px rgba(255, 0, 0, 0.53);
  border-radius: 20px;
  padding: 20px;
}

.contact-form-bg-lines {
  position: absolute;
  top: 0;
  width: 100%;
  filter: opacity(0.5);
  z-index: -1;
}

.contact-form-item label {
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 25px !important;
  letter-spacing: 1px !important;
  color: #9b9898 !important;
}

.contact-form-item {
  width: 100%;
  margin-bottom: 20px !important;
}

.contact-form-item textarea {
  color: rgb(29, 28, 28) !important;
}

.contact-button {
  color: #ffffff !important;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
}

.contact-button:hover {
  color: rgba(255, 255, 255, 0.645) !important;
  background-color: transparent !important;
}

.contact-submit-button {
  color: black !important;
  font-family: "BebasNeueLight" !important;
  font-weight: bolder !important;
  font-size: 20px !important;
  letter-spacing: 1px !important;
}

.contact-submit-button:hover {
  color: rgb(255, 0, 0) !important;
  background-color: transparent !important;
}

.contact-dialog .MuiInput-input,
.contact-dialog .MuiFormLabel-root,
.MuiInput-label {
  font-size: 20px !important;
  color: rgb(34, 32, 32) !important;
}

.contact-dialog .MuiInput-underline::before {
  border-bottom-color: black !important;
  border-bottom: 1px solid !important;
}

/* Media queries */

/* Width */

@media (max-width: 750px) {
  .contact-form-wrapper {
    width: 80%;
  }

  .contact-form-wrapper-header {
    flex-wrap: wrap;
  }

  .contact-form-wrapper-title {
    width: 100%;
  }

  .contact-form-wrapper-button {
    width: 100%;
    justify-content: flex-start;
  }
}

@media (max-width: 450px) {
  .contact-form-wrapper {
    width: 90%;
  }
}

/* Heigth */

@media (max-height: 700px) {
  .contact-form-wrapper {
    margin-top: 100px;
    margin-bottom: 30px;
  }
}
