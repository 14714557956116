.home-sidebar {
  position: fixed;
  top: 50%;
  right: 60px;
  background-color: rgb(187, 172, 172);
  height: 224px;
  width: 4px;
  margin-top: -112px;
  z-index: 999;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.sidebar-complete {
  background-color: rgb(204, 16, 16);
}

.home-sidebar-link {
  margin: 0;
  padding: 0;
  height: 32px;
  width: 4px;
  cursor: pointer;
  border-radius: 5px;
}

.home-sidebar-link:hover {
  background-color: #ffffff !important;
}

.home-sidebar-section-total,
.home-sidebar-section-actual {
  position: absolute;
  color: rgb(187, 172, 172);
  font-family: "BebasNeue";
  letter-spacing: 1px;
}

.home-sidebar-section-actual {
  top: -40px;
}

.home-sidebar-section-total {
  top: 220px;
}

/* Media queries */

/* Width */

@media (max-width: 1000px) {
  .home-sidebar {
    right: 25px;
  }
}

@media (max-width: 400px) {
  .home-sidebar {
    right: 10px;
  }
}

/* Height */

@media (max-height: 410px) {
  .home-sidebar {
    right: 25px;
  }
}
