.rolling-roads {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.rolling-roads-sections {
  height: 100%;
  background-color: transparent !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.rolling-roads-content-wrapper {
  height: 100%;
}

.rolling-roads-sections-header {
  height: 100px;
  width: 100%;
}

.rolling-roads-sections-content {
  height: calc(100% - 180px);
  width: calc(100% - 80px);
  margin-left: 20px;
  margin-right: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.rolling-roads-sections-info-wrapper {
  width: 410px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.rolling-roads-sections-title {
  font-family: "BebasNeue";
  font-weight: bolder;
  font-size: 90px;
  color: #ffffff;
  margin: 0;
  margin-bottom: 5px;
  text-align: left;
  line-height: 0.8em;
}

.rolling-roads-sections-description {
  font-family: "BebasNeueLight";
  font-weight: bolder;
  letter-spacing: 1px;
  font-size: 25px;
  color: #ffffff;
  margin: 0;
  text-align: left;
}

.rolling-roads-sections-indicators-wrapper {
  margin-top: 40px;
  width: 410px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.rolling-roads-sections-indicator-icon {
  width: 5px;
  margin-bottom: 2px;
  margin-right: 6px;
}

.rolling-roads-sections-indicator {
  color: #ffffff;
  font-family: "BebasNeue";
  font-weight: lighter;
  letter-spacing: 3px;
  font-size: 20px;
  text-align: center;
  margin: 5px;
  border-bottom: 4px solid #bab4b4;
  width: 50px;
  cursor: pointer;
}

.rolling-roads-sections-indicator:hover {
  border-bottom: 4px solid rgb(87, 83, 83) !important;
}

.rolling-roads-content {
  height: 100%;
  width: calc(100% - 60px);
  margin-left: 50px;
  margin-right: 10px;
  overflow-y: auto !important;
  scrollbar-color: red white;
  scrollbar-width: thin;
}

.rolling-roads-content-header {
  margin-top: 90px;
  width: 50px;
  margin-bottom: 20px;
  border-bottom: 4px solid #f5f5f5;
}

.rolling-roads-content-info-wrapper {
  padding-right: 10px;
  width: calc(100% - 100px);
  margin-right: 100px;
  margin-bottom: 30px;
}

.rolling-roads-content-info-text {
  font-family: "BebasNeueLight";
  font-weight: 600;
  letter-spacing: 1px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  line-height: 28px;
}

.rolling-roads-content-text-list-wrapper {
  height: 100%;
  padding-right: 10px;
  width: calc(100% - 100px);
  margin-right: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.rolling-roads-content-text-list-text-wrapper {
  margin-top: 35px;
  display: flex;
  align-items: center;
}

.rolling-roads-content-text-list-text {
  font-family: "BebasNeueLight";
  font-weight: 600;
  letter-spacing: 1px;
  color: rgb(255, 255, 255);
  font-size: 20px;
  line-height: 28px;
}

.rolling-roads-content-text-list-icon-wrapper {
  height: 40px !important;
  width: 40px !important;
  margin-right: 15px;
}

.rolling-roads-content-text-list-icon {
  height: auto;
  width: 40px;
  object-fit: contain;
}

.rolling-roads-content::-webkit-scrollbar-track {
  position: fixed !important;
  z-index: 99999 !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.rolling-roads-content::-webkit-scrollbar {
  position: fixed !important;
  z-index: 99999 !important;
  width: 6px;
  border-radius: 10px;
  background-color: #f5f5f5;
}

.rolling-roads-content::-webkit-scrollbar-thumb {
  position: fixed !important;
  z-index: 99999 !important;
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #df0f0f;
}

/* Media queries */

/* Width */

@media (max-width: 990px) {
  .rolling-roads {
    grid-template-columns: 100%;
    grid-template-rows: 500px auto;
    overflow-y: visible;
  }

  .rolling-roads-content {
    height: 100%;
    width: calc(100% - 30px);
    margin-left: 15px;
    margin-right: 15px;
    overflow-y: hidden !important;
    overflow-x: hidden !important;
  }

  .rolling-roads-content-info-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 0;
  }

  .rolling-roads-sections-content {
    height: calc(100% - 180px);
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .rolling-roads-sections-info-wrapper {
    width: 350px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .rolling-roads-sections-indicators-wrapper {
    width: 250px;
    margin-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .rolling-roads-sections-title {
    font-size: 35px;
  }

  .rolling-roads-sections-name {
    font-size: 20px;
  }

  .rolling-roads-content-text-list-wrapper {
    display: inline-block;
  }

  .rolling-roads-content-text-list-wrapper {
    height: 100%;
    padding-right: 0px;
    width: 100%;
    margin-right: 0;
  }
}

@media (max-width: 450px) {
  .rolling-roads-sections-info-wrapper {
    width: 315px;
  }

  .rolling-roads-sections-indicators-wrapper {
    width: 250px;
  }

  .rolling-roads-sections-name {
    font-size: 17px;
  }
}

@media (max-width: 330px) {
  .rolling-roads-sections-info-wrapper {
    width: 280px;
  }

  .rolling-roads-sections-indicators-wrapper {
    width: 250px;
  }

  .rolling-roads-sections-name {
    font-size: 14px;
  }
}
